import { useTranslation } from "react-i18next";
import { Container } from "../Container";
import { ReactComponent as ArrowBack } from "../../pages/SelectedTariff/icons/arrowBack.svg";
import { ErrorPayStyled, Title, Top } from "../../pages/ErrorPay/style";
import { CheckoutForm } from "./CheckoutForm";

import { PaymentFormStyled } from "./style";

import {
  CustomCheckoutProvider,
  Elements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentForm = ({
  goBack,
  tariff,
  price,
  user,
  nameOfTariff,
  isRtl,
  subscription,
  clientSecret,
  promoCode,
  language,
}) => {
  const { t } = useTranslation("landing");

  return (
    <Container
      styles={{
        padding: "18px 0px 48px 0",
        background: "#F5F5F5",
        overflow: "visible",
      }}
    >
      <ErrorPayStyled>
        <Top onClick={() => goBack()}>
          <ArrowBack
            style={{
              transform: isRtl ? "scale(-1)" : "scale(1)",
            }}
          />
          <Title isRtl={isRtl}>{t("pay")}</Title>
        </Top>
        <PaymentFormStyled>
          <Elements
            stripe={stripePromise}
            options={{
              locale: language,
              clientSecret,
              currency: "usd",
            }}
          >
            {/* <form>
              <PaymentElement />
              <button>Submit</button>
            </form> */}
            {/* <PaymentElement /> */}
            <CheckoutForm
              price={price}
              tariff={tariff}
              user={user}
              nameOfTariff={nameOfTariff}
              isRtl={isRtl}
              subscription={subscription}
              clientSecret={clientSecret}
              promoCode={promoCode}
            />
          </Elements>
        </PaymentFormStyled>
      </ErrorPayStyled>
    </Container>
  );
};

export default PaymentForm;
